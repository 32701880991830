$dark: #232c33;
$grey: #aaa;
$light: #f1f1f1;
$action: #c13f1f;
$width: 29em;

@font-face {
  font-family: body;
  src: url(Zeit-Light.otf);
}

@font-face {
  font-family: it;
  src: url("danielbk.ttf");
}

@font-face {
  font-family: caps;
  src: url(BebasNeue-Regular.ttf);
}

@mixin light-shadow {
  box-shadow: 0px 0px 50px 1px lighten($dark, 70%);
}

@mixin dark-shadow {
  box-shadow: 0px 0px 10px 1px lighten($dark, 20%);
}

@mixin light {
  background-color: $light;
  color: $dark;

  .highlight {
    color: $action;
  }
}

@mixin dark {
  background-color: $dark;
  color: $light;

  .highlight {
    background-color: lighten($dark, 10%);
  }
}

@mixin logo {
  background-color: $light;
  color: $dark;
  border-radius: 5px;
}

@mixin action {
  .cta {
    @include light-shadow();

    background-color: $action;
    color: $light;
    padding: 10px;
    text-transform: uppercase;
    margin: 1em 0 0 0;

    &:hover {
      background-color: lighten($action, 10%);
    }
  }
}

@mixin box {
  max-width: $width;
  text-align: left;
  padding: 20px;
  margin: 30px 20px;
  box-sizing: border-box;

  @media screen and (min-width: 520px) {
    padding: 50px;
  }
}

.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  background-color: $light;
  color: $dark;
  font-family: body;
  padding: 1em 0;
}

.heading {
  text-align: center;
  font-size: 1em;
  font-weight: normal;
  font-family: caps;
  color: $grey;
}

.link {
  text-decoration: none;
  color: inherit;
}

.logo {
  @include box();
  @include logo();

  text-align: center;
  font-family: it;

  .main {
    font-size: 1.8em;
    font-weight: normal;
    margin: 0;
    padding: 0;
  }

  .sub {
    font-size: 0.6em;
    display: block;
    color: darken($light, 20);
  }
}

.intro {
  @include box();
  @include light();
  @include light-shadow();

  border-bottom: solid 12px $action;

  .text {
    color: $dark;
    font-size: 1.6em;
    margin-bottom: 1em;

    @media screen and (min-width: 520px) {
      font-size: 2.3em;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    .hi {
      font-weight: bold;
      font-size: 1.5em;
      display: block;

      @media screen and (min-width: 520px) {
        font-size: larger;
      }
    }

    .iam {
    }

    .ido {
      margin-top: 1em;

      .pre {
      }

      .highlight {
      }
    }
  }
}

.map {
  @include box();

  padding: 2em 1em 0;
  display: flex;
  flex-flow: column nowrap;
  align-content: center;
  position: relative;

  .outline {
    width: 100%;
  }

  .marker {
    position: absolute;
    display: flex;
    flex-flow: row nowrap;
    font-family: it;

    .icon {
      width: 0.4em;
      height: 0.4em;
      background-color: $action;
      margin-right: 4px;

      @media screen and (min-width: 500px) {
        width: 0.5em;
        height: 0.5em;
        margin-right: 7px;
      }
    }

    .label {
      color: $action;
      font-size: 0.4em;
      text-transform: uppercase;

      @media screen and (min-width: 500px) {
        width: 0.5em;
        height: 0.5em;
        font-size: 0.5em;
      }
    }
  }

  .caption {
    font-size: 0.6em;
    text-align: center;
    margin-top: 1.5em;
    color: lighten($dark, 10%);
  }

  .zug {
    top: 32%;
    left: 55%;
  }

  .bern {
    top: 40%;
    left: 45%;
  }
}

.verbose {
  @include box();
  @include light();

  .text {
    font-size: 1.2em;
    margin: 2em 0;
  }
}

.verbose {
  .text:first-of-type {
    margin: 0 0 2em 0;
  }

  .text:last-of-type {
    margin: 2em 0 0 0;
  }
}

.my-job {
  .pre {
  }

  .highlight {
  }
}

.free-time {
  margin: 0;

  .text {
    font-size: 1em;
  }
}

.list {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  text-align: center;

  @media screen and (min-width: 680px) {
    flex-flow: row wrap;
    align-items: center;
  }
}

.skills {
  @include box();

  padding: 0;
  margin: 0;
  color: $action;
  max-width: 100%;
  width: 100%;
  text-align: center;
  font-size: 3em;
  font-family: caps;

  .list {
    .item {
      &.g1 {
        transform: rotate(-15deg);
      }

      &.g2 {
        transform: rotate(15deg);
      }
    }
  }

  .caption {
    font-size: 0.5em;
    text-align: center;
    margin-bottom: 2em;
    color: lighten($dark, 30%);
  }
}

.clients {
  .list {
    margin-top: 10px;

    .item {
      width: 250px;
      margin: 10px 20px;
    }

    .client-logo {
      width: 250px;

      @media screen and (min-width: 520px) {
        width: 100%;
      }
    }
  }
}

.action {
  @include action();
  @include box();
  @include light();
  @include light-shadow();

  margin: 3em 0;
  text-align: center;

  .text {
    font-size: 1.2em;
    margin: 0;
  }
}

.copyright {
  font-size: 0.6em;
  color: $grey;
  margin-top: 5em;
  font-family: caps;
}
